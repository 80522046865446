import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

const api = axios.create({
    baseURL: process.env.REACT_APP_RAPID_DATA
});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(localStorage.getItem('token'));


    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await api.post('/api/v1/auth/validate_user', { token });
                    const isTokenValid = response.data;
                    if (isTokenValid) {
                        setAuth(token);
                    } else {
                        setAuth(null);
                        localStorage.removeItem("token");
                    }
                } catch (error) {
                    console.error("Token validation error", error);
                    setAuth(null);
                    localStorage.removeItem("token");
                }
            }
        };

        validateToken();
    }, []);

    const login = async (email, password) => {
        try {
            const response = await api.post('/api/v1/auth/login', { email, password });
            const token = response.data.token;
            localStorage.setItem('token', token);
            setAuth(token);
            return response;
        } catch (error) {
            console.error("Login error", error);
        }
    };

    const signup = async (email, password, otp) => {
        try {
            const response = await api.post('/api/v1/auth/signup', { email, password, otp });
            const token = response.data.token;
            localStorage.setItem('token', token);
            return response;
        } catch (error) {
            console.error("Signup error", error);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setAuth(null);
    };

    const googleLogin = async (token) => {
        try {
            const response = await api.post('/api/v1/auth/google', { token });
            const authToken = response.data.token.token;
        
            localStorage.setItem('token', authToken);
            localStorage.setItem('userName', response.data.token.userName);
            localStorage.setItem('userEmail', response.data.token.email)
            setAuth(authToken);
            return response;
        } catch (error) {
            console.error("Google login error", error);
        }
    };

    return (
        <AuthContext.Provider value={{ auth, login, signup, logout, googleLogin }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
