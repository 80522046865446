import React, { useState, ChangeEvent, useEffect } from 'react';
import { Button, Typography, Card, CardContent, CardActions, Grid, Box } from '@mui/material';
import { saveFile } from '../../../api/RapidData';
import LoaderCenter from '../../../common/LoaderCenter';
import { retrieveAutoApplyUserData } from '../../../api/RapidData';
import { triggerAutoApply } from '../../../api/RapidData';
import TypingGreetings from '../../../common/TypingGreetings';

const AutoApplyTrigger: React.FC = () => {
  const [latexFile, setLatexFile] = useState<File | null>(null);
  const [details, setDetails] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [userResume, setUserResume] = useState<string>('');
  const [fileName, setFileName] = useState<string>('No file chosen'); // State for file name

  useEffect(() => {
    setLoading(true);
    const getSettings = async () => {
      try {
        const response: any = await retrieveAutoApplyUserData();
        if (response.status === 200) {
          const { email, userResume, isPremium } = response.data;
          setUserResume(userResume);

          // Pre-fill file input
          if (userResume) {
            setLatexFile(new File([], userResume)); // Create a dummy File object
            setFileName(userResume); // Set the file name
          }
          checkButtonState(new File([], userResume), details); // Check button state
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
    getSettings();
  }, []);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setLatexFile(file);
      setFileName(file.name); // Update the file name state
      checkButtonState(file, details);

      setLoading(true);
      setError(null);
      try {
        await saveFile(file.name, file);
        // Handle success, e.g., display a success message
      } catch (err) {
        setError('Failed to upload file. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  // Enable button if either file or details are provided
  const checkButtonState = (file: File | null, details: string) => {
    if (file || details.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  // Function to trigger file input click
  const handleCustomButtonClick = () => {
    document.getElementById('file-input')?.click();
  };

  const handleTriggerButton= async () => {
    try {
      setLoading(true)
      const response: any = await triggerAutoApply();
      if (response.status === 200) {
        
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }

  }

  return (
    <Box>
    <Box
      sx={{
        position: 'absolute',
        top: '12%',
        right: '20%',
        padding: 2, // Add some padding to avoid touching the edges
        zIndex: 1000, // Ensure it stays on top of other content
      }}
    >
      <TypingGreetings duration={15000} text={"Hello! " + (localStorage.getItem('userName') ?? '')} />
    </Box>
    <Grid container justifyContent="center" sx={{ marginTop: '50px' }}>
      
      {loading && <LoaderCenter message={'Loading...'} />}
      <Card sx={{
        maxWidth: 600,
        padding: 3,
        boxShadow: '0px 8px 20px rgba(0,0,0,0.1)',
        borderRadius: '15px',
        backgroundColor: '#f9f9f9',
      }}>
        <CardContent>
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Upload Your Resume
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: 1 }}>We recommend latex file</Typography>
          
          <input
            type="file"
            accept=".tex, .pdf"
            id="file-input"
            onChange={handleFileChange}
            style={{ display: 'none' }} // Hide the default file input
          />
          <Button
            variant="contained"
            onClick={handleCustomButtonClick}
            sx={{ marginBottom: 2, color: '#fff', padding: '10px 20px', borderRadius: '25px', fontWeight: 'bold', fontSize: '18px', textTransform: 'none', boxShadow: '0px 5px 15px rgba(0,0,0,0.2)', transition: '0.3s', '&:hover': { backgroundColor: '#66bb6a', boxShadow: '0px 8px 20px rgba(0,0,0,0.3)' }, '&:active': { boxShadow: 'none', backgroundColor: '#388e3c' } }}
          >
            Choose File
          </Button>
          
          {/* Display the name of the selected file */}
          <Typography variant="body2" sx={{ marginBottom: 1 }}>
            {fileName}
          </Typography>

          {error && <Typography color="error">{error}</Typography>}
        </CardContent>

        <CardActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={handleTriggerButton}
            variant="contained"
            disabled={isButtonDisabled || loading}
            sx={{
              backgroundColor: isButtonDisabled ? '#ccc' : '#4caf50',
              color: '#fff',
              padding: '10px 20px',
              borderRadius: '25px',
              fontWeight: 'bold',
              fontSize: '18px',
              textTransform: 'none',
              boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
              transition: '0.3s',
              '&:hover': {
                backgroundColor: !isButtonDisabled ? '#66bb6a' : '#ccc',
                boxShadow: !isButtonDisabled ? '0px 8px 20px rgba(0,0,0,0.3)' : 'none',
              },
              '&:active': {
                boxShadow: 'none',
                backgroundColor: !isButtonDisabled ? '#388e3c' : '#ccc',
              },
            }}
          >
            Trigger
          </Button>
        </CardActions>
      </Card>
    </Grid>
    </Box>
  );
};

export default AutoApplyTrigger;
