import React from 'react';
import {Box} from '@mui/material';
import LoaderApp from './Loader';

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 499, // Slightly less than the loader to ensure loader is on top
    pointerEvents: 'none', // Prevent interactions with the overlay itself
  },
  loginGate: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 500, // High z-index to be on top of everything
    
    padding: '20px', // Optional: padding for content
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Optional: shadow for better visibility
  },
};

const LoaderCenter = ({message}) => {
  return (
    <>
      <Box sx={styles.overlay}></Box>
      <Box sx={styles.loginGate}>
        <LoaderApp message={message} />
      </Box>
    </>
  );
};

export default LoaderCenter;
