import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_RAPID_DATA
})


// Utility function to introduce delay
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Minimum 3-second delay function
const withMinDelay = async (apiCallPromise ,minDelay = 1000) => {
    const startTime = Date.now();

    // Execute the API call
    const response = await apiCallPromise;

    const elapsedTime = Date.now() - startTime;
    const remainingTime = minDelay - elapsedTime;

    // Wait if the API call finished before 3 seconds
    if (remainingTime > 0) {
        await delay(remainingTime);
    }

    return response;
};

// auto-apply
/////////////////////////////////////////////////////////////////////////////

// Save settings API
const save_settings = async (linkedinDetailsRequest) => {
    const userEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("token");

    if (!userEmail) {
        console.error("User email not found in local storage.");
        return false;
    }

    try {
        // Wrap the API call with minimum delay
        return await withMinDelay(api.post(
            `/api/v1/rapid-data/auto_apply/save_settings?userEmail=${encodeURIComponent(userEmail)}`,
            linkedinDetailsRequest,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        ));
    } catch (error) {
        console.error("Error saving settings:", error);
        return false;
    }
};

// Retrieve settings API
const retrieve_settings = async () => {
    const userEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("token");

    try {
        // Wrap the API call with minimum delay
        return await withMinDelay(api.get(
            '/api/v1/rapid-data/auto_apply/retrieve_settings',
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    userEmail // Pass userEmail as a query parameter
                }
            }
        ));
    } catch (error) {
        console.error("Error retrieving settings:", error);
        return false;
    }
};

// Retrieve settings API
const  retrieveAutoApplyUserData = async () => {
    const userEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("token");
    try {
        // Wrap the API call with minimum delay
        return await withMinDelay(api.get(
            '/api/v1/rapid-data/auto_apply/retrieve_aa_user_daa',
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    userEmail // Pass userEmail as a query parameter
                }
            }
        ));
    } catch (error) {
        console.error("Error retrieving settings:", error);
        return false;
    }
};


// Retrieve settings API
const saveAutoApplyUserData = async (fileName, isPremium) => {
    const userEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("token");

    try {
        // Wrap the API call with minimum delay
        const body = {
            email: userEmail,
            fileName: fileName,
            isPremium: isPremium
        }
        return await withMinDelay(api.post(
            '/api/v1/rapid-data/auto_apply/save_aa_user_daa', body, 
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        ));
    } catch (error) {
        console.error("Error retrieving settings:", error);
        return false;
    }
};





const otpCall= async (email) => {
    try {
        return await api.post('/api/v1/auth/otp', {email});
        
    } catch (error) {
        return false;
    }
};




const saveFile = async (fileName, file) => {
    try {
        // Create FormData to handle file uploads
        const updatedFileName =  fileName + '@' + localStorage.getItem("userEmail");
        const token = localStorage.getItem("token");

        const formData = new FormData();
        formData.append('fileName', updatedFileName);
        formData.append('file', file);

        // Send POST request with FormData
        const response = await api.post('/api/v1/logical/auto_apply/upload-file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                 Authorization: `Bearer ${token}`
            }
        });

        // Handle and return the response
        return response.data;
    } catch (error) {
        // Improve error handling with logging and rethrowing
        console.error('Error uploading file:', error.response || error.message || error);
        throw error; // Rethrow error after logging it
    }
};

const triggerAutoApply = async () => {
    try {
        // Create FormData to handle file uploads
        const userEmail =  localStorage.getItem("userEmail");
        const token = localStorage.getItem("token");

    

        // Send POST request with FormData
        const response = await api.get('/api/v1/logical/auto_apply/trigger', {
            headers: {
                 Authorization: `Bearer ${token}`
            },
            params: {
                userEmail // Pass userEmail as a query parameter
            }
        });

        // Handle and return the response
        return response.data;
    } catch (error) {
        // Improve error handling with logging and rethrowing
        console.error('Error while triggering:', error.response || error.message || error);
        throw error; // Rethrow error after logging it
    }
};


const usersAllFile = async () => {
    try {
        // Create FormData to handle file uploads
        const userEmail =  localStorage.getItem("userEmail");
        const token = localStorage.getItem("token");

    

        // Send POST request with FormData
        const response = await api.get('/api/v1/logical/auto_apply/users_all_file', {
            headers: {
                 Authorization: `Bearer ${token}`
            },
            params: {
                userEmail // Pass userEmail as a query parameter
            }
        });
        // Handle and return the response
        return response.data;
    } catch (error) {
        // Improve error handling with logging and rethrowing
        console.error('Error while triggering:', error.response || error.message || error);
        throw error; // Rethrow error after logging it
    }
};

const downloadUserResume = async (fileName) => {
    try {
        // Create FormData to handle file uploads
        const userEmail =  localStorage.getItem("userEmail");
        const token = localStorage.getItem("token");

        // Send POST request with FormData
        const response = await api.get('/api/v1/logical/auto_apply/download_user_resume', {
            headers: {
                 Authorization: `Bearer ${token}`
            },
            params: {
                userEmail,
                fileName 
            }
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName + ".pdf"); 
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error downloading the file', error);
    }
}

export {saveFile, otpCall, triggerAutoApply, usersAllFile, downloadUserResume, save_settings, retrieve_settings, retrieveAutoApplyUserData, saveAutoApplyUserData}

