import React from 'react';
import { Box, Button, Typography, Container, Card, CardContent, IconButton, Chip, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TelegramIcon from '@mui/icons-material/Telegram';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PaymentIcon from '@mui/icons-material/Payment';

const ContactPage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        backgroundImage: `url(/colorRainBackground.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Container
        sx={{
          maxWidth: 'sm',
          padding: theme.spacing(4),
          bgcolor: theme.palette.grey[900],
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[10],
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{ color: theme.palette.common.white, mb: 3, fontWeight: 'bold' }}
        >
          Contact Us
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: theme.palette.grey[300], mb: 4 }}
        >
          We're here to assist you with any issues or questions you might have. Reach out to us for help with:
        </Typography>
        <Stack spacing={2} mb={4}>
          <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: theme.palette.grey[800] }}>
            <CardContent sx={{ flex: 1 }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton color="primary">
                  <HelpOutlineIcon />
                </IconButton>
                <Typography variant="body1" sx={{ color: theme.palette.grey[300] }}>
                  Application Usage
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: theme.palette.grey[800] }}>
            <CardContent sx={{ flex: 1 }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton color="primary">
                  <PaymentIcon />
                </IconButton>
                <Typography variant="body1" sx={{ color: theme.palette.grey[300] }}>
                  Payment Issues
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: theme.palette.grey[800] }}>
            <CardContent sx={{ flex: 1 }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton color="primary">
                  <HelpOutlineIcon />
                </IconButton>
                <Typography variant="body1" sx={{ color: theme.palette.grey[300] }}>
                  General Inquiries
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
        <Button
          variant="contained"
          color="primary"
          href="https://t.me/YourTelegramChannel" // Replace with your actual Telegram channel link
          sx={{ padding: theme.spacing(1.5, 4), fontSize: '1rem', borderRadius: '20px' }}
          startIcon={<TelegramIcon />}
        >
          Join Our Telegram Channel
        </Button>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          bottom: theme.spacing(2),
          color: theme.palette.grey[300],
        }}
      >
        <Chip label="Need more help? Visit our FAQs" color="info" clickable component="a" href="/faq" />
      </Box>
    </Box>
  );
};

export default ContactPage;
