import React, {useState} from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { AuthContext } from './AuthProvider';
import { useNavigate } from 'react-router-dom'
import LoaderCenter from '../../src/common/LoaderCenter';




const GoogleLoginButton = () => {
    const navigate = useNavigate();
    const { googleLogin } = React.useContext(AuthContext);
    const [showLoader, setShowLoader] = useState(false);
    const handleSuccess = async (response) => {
        const token = response.credential; // Use `credential` to get the token
        const jwtTokneResponse = await googleLogin(response);
        try {
            setShowLoader(true)
            googleLogin(token)
            .then((response) => {
                if(response.status == 200)
                {
                    navigate('/auth/aamain'); 
                }
                else
                {
                    setShowLoader(false);
                }
                
            })
            .catch((error) => {
                setShowLoader(false);
            });
            
        } catch (error) {
           
        }
    };

    const handleError = (error) => {
        console.error("Google Sign-In Error:", error);
    };
    
    return (
        <>
            {showLoader && <LoaderCenter message={"Loading"} />}
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <GoogleLogin
                    onSuccess={handleSuccess}
                    onError={handleError}
                    style={{ margin: '20px' }} // Optional styling
                />
        </GoogleOAuthProvider>
    </>
    )

}

export default GoogleLoginButton;
