import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from './api/AuthProvider';

const PrivateRoute = ({ element: Element, ...rest }) => {
    const { auth } = useContext(AuthContext);
    return auth ? <Element {...rest} /> : <Navigate to="/auth" />;
};

export default PrivateRoute;
