import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'black',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
      }}
    >
      <Typography variant="body2">
        <Link href="/" style={{ color: 'white' }}>Home</Link> |{' '}
        <Link href="/about" style={{ color: 'white' }}>About</Link> |{' '}
        <Link href="/contact" style={{ color: 'white' }}>Contact</Link>
      </Typography>
      <Typography variant="caption">
        &copy; {new Date().getFullYear()} Auto Improved Apply
      </Typography>
    </Box>
  );
};

export default Footer;