import React from 'react';
import { Card, CardContent, Typography, Button, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AutoApplyPricingPage = () => {
  const pricingOptions = [
    {
      title: 'Free',
      price: 'Free',
      features: [
        "2 apply per day MAX",
        'Use Your Own Resume, No Modification',
        'Low server bandwidth',
        '2 apply per 24 hr',
        'contains ads',
        "Low Success Rate"
      ],
    },
    {
      title: 'Silver',
      price: 'Rs 99/month',
      features: [
        '120 applies per day MAX',
        'Use Your Own Resume, No Modification',
        'High server bandwidth',
        '10 applies per 2 hr',
        'contains ads',
        "Low Success Rate"
      ],
    },
    {
      title: 'Gold',
      price: 'Rs 499/month',
      features: [
        "240 apply per day MAX",
        'AI modified resume before applying',
        'Very High server bandwidth',
        '20 apply per 1 hr',
        'no ads',
        "High Success Rate"
      ],
    }
  ];

  return (
    <Grid container spacing={4} justifyContent="center" sx={{ padding: 4 }}>
      {pricingOptions.map((option, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              minHeight: 300,
              boxShadow: index === 1 ? 5 : 1,
              border: index === 1 ? '2px solid #FFD700' : '1px solid #ccc',
              backgroundColor: index === 1 ? '#f7f7f7' : 'white',
              '&:hover': {
                transform: 'scale(1.05)',
                transition: 'transform 0.3s ease-in-out',
              },
            }}
          >
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 'bold' }}>
                {option.title}
              </Typography>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                {option.price}
              </Typography>

              <List>
                {option.features.map((feature, idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>

              <Button
                variant={index === 1 ? 'contained' : 'outlined'}
                color="primary"
                fullWidth
                sx={{
                  marginTop: 3,
                  padding: 1,
                  fontWeight: 'bold',
                  borderRadius: 3,
                }}
              >
                {index === 1 ? 'Go Premium' : 'Choose Free'}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AutoApplyPricingPage;
