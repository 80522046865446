import React, { useState, useContext } from 'react';
import {
    Box,
    TextField,
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    Grid,
  } from "@mui/material";
import Authentication from '../../Authentication/Authentication';
import AutoApplyNavBar from './AutoApplyNavBar';



const AutoApplyMainPage= () => {
    return (
        <Box
        sx={{
            display: "flex",
            width: "100%",
            height: "100vh",
            backgroundImage: `url(/colorRainBackground.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxSizing: "border-box",
          }}>
           <AutoApplyNavBar/>
        </Box>
    )
}


export default AutoApplyMainPage;