import React, { useState, useEffect } from 'react';

interface TypingGreetingsProps {
  text: string;
  duration: number;
}

const TypingGreetings: React.FC<TypingGreetingsProps> = ({ text, duration }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [fontSize, setFontSize] = useState('4rem'); // Default font size

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setFontSize('1rem'); // Smaller font size for narrow screens
      } else {
        setFontSize('3rem'); // Default font size
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Set initial font size
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let typingTimeout: NodeJS.Timeout;

    if (isTyping && !isPaused) {
      if (index < text.length) {
        typingTimeout = setTimeout(() => {
          setDisplayedText((prev) => prev + text[index]);
          setIndex((prev) => prev + 1);
        }, 100); // Adjust typing speed by changing the timeout duration
      } else {
        setIsPaused(true);
        setTimeout(() => {
          setDisplayedText('');
          setIndex(0);
          setIsPaused(false);
        }, duration); // Wait for duration before resetting
      }
    }

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [index, isTyping, isPaused, text, duration]);

  return (
    <div
      style={{
        fontSize: fontSize, 
        fontWeight: 'bold', 
        fontFamily: 'Google Sans, Helvetica Neue, sans-serif',
        padding: '20px', 
        color: 'red',
        borderRadius: '10px',      
        background: 'radial-gradient(circle, rgba(24,224,171,0.9472163865546218) 17%, rgba(224,26,40,1) 60%, rgba(47,229,0,1) 95%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        textAlign: 'center' // Center text
      }}
    >
      {displayedText}
      <span className="cursor">|</span>
    </div>
  );
};

export default TypingGreetings;
