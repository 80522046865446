import React, { useState } from 'react';
import { Box, Card, TextField, Button, IconButton, InputAdornment, useTheme, useMediaQuery } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoaderCenter from '../../common/LoaderCenter';
import SignUpGate from './SignupGate';
import { otpCall } from '../../api/RapidData';
import GoogleLoginButton from '../../api/GoogleLoginButton';

interface LoginProps {
    setLoginOrSignup: React.Dispatch<React.SetStateAction<boolean>>;
}

const Signup: React.FC<LoginProps> = ({ setLoginOrSignup }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showOtpScreen, setShowOtpScreen] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        if (e.target.value.length < 8) {
            setPasswordError('Password must be at least 8 characters long');
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        if (e.target.value !== password) {
            setConfirmPasswordError('Passwords do not match');
        } else {
            setConfirmPasswordError('');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSignup = () => {
        if (password.length >= 8 && password === confirmPassword) {
            setShowLoader(true);
            otpCall(email)
                .then((response: any) => {
                    if (response.status === 200) {
                        setShowOtpScreen(true);
                    } else {
                        setShowOtpScreen(false);
                    }
                    setShowLoader(false);
                })
                .catch(() => {
                    setShowLoader(false);
                    setShowOtpScreen(false);
                });
        } else {
            if (password.length < 8) {
                setPasswordError('Password must be at least 8 characters long');
            }
            if (password !== confirmPassword) {
                setConfirmPasswordError('Passwords do not match');
            }
        }
    };

    return (
        <Card
            sx={{
                backgroundColor: 'rgba(255, 255, 255, 1)',
                boxShadow: 'none',
                width: isSmallScreen ? '90%' : '70%', // Responsive width
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'auto', // Flexible height
                minHeight: '60vh',
                padding: isSmallScreen ? '16px' : '32px', // Padding for smaller screens
                borderRadius: '20px',
                margin: isSmallScreen ? '16px' : 'auto' // Margin for smaller screens
            }}
        >
            {showLoader && <LoaderCenter message={"Loading"} />}
            {showOtpScreen && <SignUpGate password={password} email={email} setShowLoader={setShowLoader} setShowOtpScreen={setShowOtpScreen} />}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: '100%' }}>
                <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    id="outlined-password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                />
                <TextField
                    id="outlined-confirm-password"
                    label="Confirm Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    fullWidth
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    error={Boolean(confirmPasswordError)}
                    helperText={confirmPasswordError}
                />
                <Button variant="contained" onClick={handleSignup} fullWidth>
                    Signup
                </Button>
                <Button onClick={() => setLoginOrSignup(true)} fullWidth>
                    Login
                </Button>
                <GoogleLoginButton />
            </Box>
        </Card>
    );
};

export default Signup;
